<template>
    <div class="width-full h-screen fixed top-0 right-0 flex items-center justify-center">
        <Card class="flex p-4 flex-col" style="width: 500px">
            <p class="w-full font-extrabold text-xs text-jetOpacity">+ Add New Group</p>

            <GroupToolTip :isGroupToolTip="isGroupToolTip"
                @on-enter="isGroupToolTip = true"
                @on-leave="isGroupToolTip = false"
            />

            <div class="col-span-3">
                <c-text
                    placeholder="--Enter--"
                    variant="w-full"
                    v-model="newGroup"
                />
            </div>

            <div class="w-full my-4 flex items-end justify-end">
                <button
                    class="w-auto h-auto text-flame text-xs font-semibold uppercase border-none outline-none"
                    :disabled="disableAddBtn"
                    @click="submitGroup"
                >
                    {{ disableAddBtn ? 'Saving...' : 'Save' }}
                </button>

                <button
                    background-color="none"
                    class="w-auto h-auto ml-4 text-jetOpacity text-xs font-semibold uppercase border-none outline-none"
                    :disabled="disableAddBtn"
                    @click="closeModal"
                >
                    Cancel
                </button>
            </div>

            <div class="w-full pb-4 b-top">
                <div v-for="(group, index) in filteredGroups" :key="index" class="w-full">
                    <div class="w-full h-9 flex justify-between items-center">
                        <p class="text-jet text-sm">{{ group.name }}</p>
                        <div class="flex justify-center items-center">
                            <span @click="proceedEdit(group.name, group.id)" class="cursor-pointer pr-4">
                                <icon icon-name="edit" size="xs" class-name="text-mediumSeaGreen"/>
                            </span>
                            <span @click="proceedDelete(group.name, group.id)" class="cursor-pointer pl-4 inline-block b-left">
                                <icon icon-name="delete" size="xs" class-name="text-desire"/>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </Card>    
    </div>
</template>
<script>
export default {
    name: "AddGroup",
    components: {
        CText: () => import("@scelloo/cloudenly-ui/src/components/text"),
        Card: () => import("../../Card"),
        GroupToolTip: () => import("./GroupToolTip")
    },
    props: {
        disableAddBtn: Boolean,
        groups: {
            type: Array,
            default: () => []
        },
    },
    data() {
        return {
            isGroupToolTip: false,
            isSubmitting: false,
            newGroup: ''
        }
    },
    computed: {
        filteredGroups() {
            return this.groups.filter(group => group.id !== "234");
        }
    },
    methods: {
        closeModal(){
            this.$emit("cancel");
        },
        proceedDelete(group, groupId){
            this.$emit("proceed-delete", group, groupId);
        },
        proceedEdit(group, groupId){
            this.$emit("proceed-edit", group, groupId);
        },
        submitGroup(){
            this.$emit("add-group", this.newGroup)
        }
    },
};
</script>
<style scoped>
.width-full{
    width: 38.5rem;
    z-index: 9999 !important;
    background: rgba(0, 0, 0, .5) !important;
}
.b-top{
    border-top: 1px solid #00000029;
}
.b-left{
    border-left: 1px solid #0000003D
}
</style>
